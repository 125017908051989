canvas,
.canvas > div {
	z-index: 1;
}

canvas {
  &.on-hover {
    cursor: pointer;
  }
}

.loading {
	padding: 10px;
	// transform: translate3d(-50%, -50%, 0);
	color: #eee;
}
