@import './klett_variables';

.App {
	width: 100%;
	height: 100%;
}

.fullscreen {
	width: 100%;
	height: 100%;
}

#assetviewer {
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 10;

	div {
		pointer-events: all;
	}
}

#modalanchor {
	z-index: 20;
}

// Basic styles

body {
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $color-copy-text;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1 {
	font-size: $font-size-title-1;
	font-weight: bold;
	line-height: $line-height-headline;
}
h2 {
	font-size: $font-size-title-2;
	font-weight: normal;
	line-height: $line-height-headline;
}
h3,
h4 {
	font-size: $font-size-copy;
	font-weight: bold;
	line-height: $line-height-headline;
}

p,
div,
table {
	font-size: $font-size-copy;
}

p {
	margin-bottom: .5em;
}

.reference {
	font-size: $font-size-reference;
}

.btn {
	&.filled {
		background-color: $klett-neutral-grey-3;
		color: $color-copy-text;
		border: none;

		&:hover {
			background-color: $klett-neutral-grey-5;
		}

		&.rounded {
			height: 42px;
			min-width: 130px;
			border-radius: 21px;
		}
	}
}

.icon-btn {
	width: $icon-size;
	height: $icon-size;
	border: none;
	position: relative;
	z-index: 20;
	&:focus,
	&:hover {
		background: none;
		border: none;
		box-shadow: none;
		&::after {
			transform: scale(1.15);
		}
	}
	&:active {
		background: none;
		border: none;
	}

	&::after {
		content: '';
    display: block;
    left: 0%;
    position: absolute;
		top: 0%;
		width: $icon-size;
		height: $icon-size;
		transform-origin: center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: transform .2s;
	}

	&.icon-close {
		float: right;
		margin: -10px -10px 10px 0;
		&::after {
			background-image: url('/assets/icons/icon_close_64.png');
		}
	}
}

.main-toolbar {
	position: fixed;
	top: 0;
	right: 0;
	//padding-right: 15px;
	pointer-events: all;
}

.ui-btn {
	width: $ui-btn-size;
	height: $ui-btn-size;
	position: relative;
	border-radius: 0;
	background: rgba(194, 190, 192, .75);
	border: none;
	color: $color-title-grey;
	&:focus {
		border: none;
		box-shadow: none;
		background-color: rgba(194, 190, 192, .75);
	}
	&:hover {
		border: none;
		background: rgba(231, 226, 229, .75);
	}

}

.figure-caption {
	line-height: 1.3;
}

// Modal classes

.ReactModal__Body--open {
	overflow: hidden;
}

.modal {
	// position: absolute;
	// top: 40px;
	// left: 40px;
	// right: 40px;
	// bottom: 40px;
	//width: 100%;
	min-width: 40%;
	max-width: 900px;
	min-height: 80px;
	max-height: 94%;
	// position: fixed;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%);
	border: 1px solid darken($modal-background-color, 15%);
	background: $modal-background-color;
	box-shadow: 0 0 2px 3px rgba(0, 0, 0, .15);
	overflow: hidden;
	//border-radius: 4px;
	outline: none;
	padding: 20px;
	margin: 15px;
	position: relative;

	&.large {
		max-width: 86%;
	}
	&.medium {
		max-width: 76%;
	}
	&.small {
		max-width: 54%;
	}

	@media screen and (orientation: landscape) {
		&.landscape-w35 {
			max-width: 35%;
		}
		&.landscape-w40 {
			max-width: 40%;
		}
		&.landscape-w45 {
			max-width: 45%;
		}
		&.landscape-w50 {
			max-width: 50%;
		}
		&.landscape-w55 {
			max-width: 55%;
		}
		&.landscape-w60 {
			max-width: 60%;
		}
		&.landscape-w65 {
			max-width: 65%;
		}
		&.landscape-w70 {
			max-width: 70%;
		}
		&.landscape-w75 {
			max-width: 75%;
		}
		&.landscape-w80 {
			max-width: 80%;
		}
	}

	.scroll-content {
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.icon-btn {
		background-color: rgba(255, 255, 255, 0.7);

		&.icon-close {
			position: absolute;
			margin: 0;
			top: 12px;
			right: 12px;
			z-index: 20;
		}
	}

	.panel {
		border: none;
		border-radius: 0;
		max-height: 85vh;

		.panel-body {
			padding: 0;
		}
	}

}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $modal-backdrop-color;
	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

// #modal-container {
// 	position: relative;
// }

// Dialogs 

.dialog-footer {
	margin-top: 2em;
}

// Carousel

.klett-carousel {
	.carousel.carousel-slider .control-arrow {
		padding: 8px;
		background: rgba(0, 0, 0, .2);
		opacity: 1;
		&::before {
			opacity: .8;
		}
		&:hover {
			background: rgba(0, 0, 0, .5);
			&::before {
				opacity: 1;
			}
		}
	}

}
